/**
 * Mixins docs:
 * https://es.vuejs.org/v2/guide/mixins.html
 * https://codingpotions.com/vue-mixins
 */

export const mixinDates = {
  methods: {
    getCurrentDate() { // YYYY-MM-DD format
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      // validar días y meses menores a 10
      if (month < 10) {
        if (day < 10) {
          return `${year}-0${month}-0${day}`
        }
        return `${year}-0${month}-${day}`
      }
      if (day < 10) {
        return `${year}-${month}-0${day}`
      }
      return `${year}-${month}-${day}`
    },
  },
}

export const _ = null
